html, body {
    margin: 0;
    width: 100%;
    height: 100%;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

div {
    box-sizing: border-box;
}

.side-nav-content {
    padding: 15px 10px;
}

a:hover, button:hover {
    cursor: pointer;
}


.multiselect-dropdown .dropdown-btn {
  display: inline-block;
  border: 0px !important;
  border-bottom: 1px solid var(--mat-list-color) !important;
  width: 100%;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.1;
  text-align: left;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;

  .dropdown-down {
    border-top: 5px solid var(--dark-label-color) !important;
    border-left: 5px solid var(--regular-transparent-color) !important;
    border-right: 5px solid var(--regular-transparent-color) !important;
  }
}


.mat-progress-bar-background{
  fill: whitesmoke !important;
}


.mat-button {
  color: var(--link-color);
}

.mat-form-field-type-mat-input.mat-focused {

  .mat-form-field-infix {
    transition: all 500ms;
    padding-top: 5px;
    border: 1px solid var(--primary-color);
  }
}
