.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-h1, .mat-headline, .mat-typography h1 {
  font: 400 24px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2, .mat-title, .mat-typography h2 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h3, .mat-subheading-2, .mat-typography h3 {
  font: 400 16px / 28px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h4, .mat-subheading-1, .mat-typography h4 {
  font: 400 15px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5, .mat-typography h5 {
  font: 400 calc(14px * 0.83) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-h6, .mat-typography h6 {
  font: 400 calc(14px * 0.67) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-body-strong, .mat-body-2 {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-body, .mat-body-1, .mat-typography {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-body p, .mat-body-1 p, .mat-typography p {
  margin: 0 0 12px;
}

.mat-small, .mat-caption {
  font: 400 12px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-display-4, .mat-typography .mat-display-4 {
  font: 300 112px / 112px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}

.mat-display-3, .mat-typography .mat-display-3 {
  font: 400 56px / 56px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}

.mat-display-2, .mat-typography .mat-display-2 {
  font: 400 45px / 48px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}

.mat-display-1, .mat-typography .mat-display-1 {
  font: 400 34px / 40px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-bottom-sheet-container {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-button-toggle {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}

.mat-card-header .mat-card-title {
  font-size: 20px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}

.mat-checkbox {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}

.mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-table {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}

.mat-cell, .mat-footer-cell {
  font-size: 14px;
}

.mat-calendar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-dialog-title {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-expansion-panel-header {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.mat-expansion-panel-content {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-form-field-label {
  top: 1.34375em;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  width: 133.3333333333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  width: 133.3333433333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  width: 133.3333533333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28122em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28121em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

input.mat-input-element {
  margin-top: -0.0625em;
}

.mat-menu-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
}

.mat-radio-button {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select-trigger {
  height: 1.125em;
}

.mat-slide-toggle-content {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-slider-thumb-label-text {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}

.mat-tab-group {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tab-label, .mat-tab-link {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0;
}

.mat-tooltip {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-list-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
}

.mat-optgroup-label {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-simple-snackbar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.mat-tree {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale(0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop, .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start {
  /*!*/
}
@keyframes cdk-text-field-autofill-end {
  /*!*/
}
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}

.mat-mdc-focus-indicator {
  position: relative;
}

.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #4793d1;
}

.mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #162b50;
}

.mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #162b50;
}

.mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.mat-pseudo-checkbox::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #4793d1;
}

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #162b50;
}

.mat-warn .mat-pseudo-checkbox-checked,
.mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #162b50;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}

.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}

.mat-badge {
  position: relative;
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

.mat-badge-content {
  color: white;
  background: #4793d1;
}
.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.mat-badge-accent .mat-badge-content {
  background: #162b50;
  color: white;
}

.mat-badge-warn .mat-badge-content {
  color: white;
  background: #162b50;
}

.mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}

.mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-button, .mat-icon-button, .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
  color: #4793d1;
}
.mat-button.mat-accent, .mat-icon-button.mat-accent, .mat-stroked-button.mat-accent {
  color: #162b50;
}
.mat-button.mat-warn, .mat-icon-button.mat-warn, .mat-stroked-button.mat-warn {
  color: #162b50;
}
.mat-button.mat-primary.mat-button-disabled, .mat-button.mat-accent.mat-button-disabled, .mat-button.mat-warn.mat-button-disabled, .mat-button.mat-button-disabled.mat-button-disabled, .mat-icon-button.mat-primary.mat-button-disabled, .mat-icon-button.mat-accent.mat-button-disabled, .mat-icon-button.mat-warn.mat-button-disabled, .mat-icon-button.mat-button-disabled.mat-button-disabled, .mat-stroked-button.mat-primary.mat-button-disabled, .mat-stroked-button.mat-accent.mat-button-disabled, .mat-stroked-button.mat-warn.mat-button-disabled, .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.mat-button.mat-primary .mat-button-focus-overlay, .mat-icon-button.mat-primary .mat-button-focus-overlay, .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #4793d1;
}
.mat-button.mat-accent .mat-button-focus-overlay, .mat-icon-button.mat-accent .mat-button-focus-overlay, .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #162b50;
}
.mat-button.mat-warn .mat-button-focus-overlay, .mat-icon-button.mat-warn .mat-button-focus-overlay, .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #162b50;
}
.mat-button.mat-button-disabled .mat-button-focus-overlay, .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.mat-button .mat-ripple-element, .mat-icon-button .mat-ripple-element, .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}

.mat-button-focus-overlay {
  background: black;
}

.mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}

.mat-flat-button, .mat-raised-button, .mat-fab, .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  color: white;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  color: white;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  color: white;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  background-color: #4793d1;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  background-color: #162b50;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  background-color: #162b50;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-flat-button.mat-primary .mat-ripple-element, .mat-raised-button.mat-primary .mat-ripple-element, .mat-fab.mat-primary .mat-ripple-element, .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-accent .mat-ripple-element, .mat-raised-button.mat-accent .mat-ripple-element, .mat-fab.mat-accent .mat-ripple-element, .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-warn .mat-ripple-element, .mat-raised-button.mat-warn .mat-ripple-element, .mat-fab.mat-warn .mat-ripple-element, .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-stroked-button:not([class*=mat-elevation-z]), .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-fab:not([class*=mat-elevation-z]), .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone,
.mat-button-toggle-group {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  box-shadow: none;
}

.mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
}

[dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}

.mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}

.mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-checkmark {
  fill: #fafafa;
}

.mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}

.mat-checkbox-mixedmark {
  background-color: #fafafa;
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #4793d1;
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #162b50;
}
.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #162b50;
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox .mat-ripple-element {
  background-color: black;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #4793d1;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #162b50;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #162b50;
}

.mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.mat-chip.mat-standard-chip::after {
  background: black;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #4793d1;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #162b50;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #162b50;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-table {
  background: white;
}

.mat-table thead, .mat-table tbody, .mat-table tfoot,
mat-header-row, mat-row, mat-footer-row,
[mat-header-row], [mat-row], [mat-footer-row],
.mat-table-sticky {
  background: inherit;
}

mat-row, mat-header-row, mat-footer-row,
th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}

.mat-cell, .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}

.mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}

.mat-calendar-table-header,
.mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-body-cell-content,
.mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}

.mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}

.mat-calendar-body-in-range::before {
  background: rgba(71, 147, 209, 0.2);
}

.mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}

.mat-calendar-body-comparison-bridge-start::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(71, 147, 209, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-comparison-bridge-end::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(71, 147, 209, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}

.mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}

.mat-calendar-body-selected {
  background-color: #4793d1;
  color: white;
}

.mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(71, 147, 209, 0.4);
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}

.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(71, 147, 209, 0.3);
}

@media (hover: hover) {
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(71, 147, 209, 0.3);
  }
}
.mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(22, 43, 80, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(22, 43, 80, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(22, 43, 80, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #162b50;
  color: white;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(22, 43, 80, 0.4);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(22, 43, 80, 0.3);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(22, 43, 80, 0.3);
  }
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(22, 43, 80, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(22, 43, 80, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(22, 43, 80, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #162b50;
  color: white;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(22, 43, 80, 0.4);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(22, 43, 80, 0.3);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(22, 43, 80, 0.3);
  }
}

.mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-datepicker-toggle-active {
  color: #4793d1;
}
.mat-datepicker-toggle-active.mat-accent {
  color: #162b50;
}
.mat-datepicker-toggle-active.mat-warn {
  color: #162b50;
}

.mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}

.mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}

.mat-expansion-panel-header {
  height: 48px;
}
.mat-expansion-panel-header.mat-expanded {
  height: 64px;
}

.mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}

.mat-hint {
  color: rgba(0, 0, 0, 0.6);
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: #4793d1;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #162b50;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #162b50;
}

.mat-focused .mat-form-field-required-marker {
  color: #162b50;
}

.mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #4793d1;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #162b50;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #162b50;
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #4793d1;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #162b50;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #162b50;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #162b50;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #162b50;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #162b50;
}

.mat-error {
  color: #162b50;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #4793d1;
}
.mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #162b50;
}
.mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #162b50;
}
.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #162b50;
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}

.mat-icon.mat-primary {
  color: #4793d1;
}
.mat-icon.mat-accent {
  color: #162b50;
}
.mat-icon.mat-warn {
  color: #162b50;
}

.mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}

.mat-input-element {
  caret-color: #4793d1;
}
.mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-form-field.mat-accent .mat-input-element {
  caret-color: #162b50;
}

.mat-form-field.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
  caret-color: #162b50;
}

.mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #162b50;
}

.mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}

.mat-list-option:hover, .mat-list-option:focus,
.mat-nav-list .mat-list-item:hover,
.mat-nav-list .mat-list-item:focus,
.mat-action-list .mat-list-item:hover,
.mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}

.mat-list-single-selected-option, .mat-list-single-selected-option:hover, .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}

.mat-menu-panel {
  background: white;
}
.mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.mat-menu-item[disabled],
.mat-menu-item[disabled] .mat-menu-submenu-icon,
.mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}

.mat-menu-item .mat-icon-no-color,
.mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}

.mat-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}

.mat-paginator {
  background: white;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}

.mat-paginator-decrement,
.mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-paginator-first,
.mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-icon-button[disabled] .mat-paginator-decrement,
.mat-icon-button[disabled] .mat-paginator-increment,
.mat-icon-button[disabled] .mat-paginator-first,
.mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-paginator-container {
  min-height: 56px;
}

.mat-progress-bar-background {
  fill: #cde0f0;
}

.mat-progress-bar-buffer {
  background-color: #cde0f0;
}

.mat-progress-bar-fill::after {
  background-color: #4793d1;
}

.mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #c1c6d0;
}
.mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #c1c6d0;
}
.mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #162b50;
}

.mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #c1c6d0;
}
.mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #c1c6d0;
}
.mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #162b50;
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #4793d1;
}
.mat-progress-spinner.mat-accent circle, .mat-spinner.mat-accent circle {
  stroke: #162b50;
}
.mat-progress-spinner.mat-warn circle, .mat-spinner.mat-warn circle {
  stroke: #162b50;
}

.mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #4793d1;
}
.mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #4793d1;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #162b50;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #162b50;
}
.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #162b50;
}
.mat-radio-button.mat-warn .mat-radio-inner-circle,
.mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #162b50;
}
.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button .mat-ripple-element {
  background-color: black;
}

.mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}

.mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}

.mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}

.mat-select-panel {
  background: white;
}
.mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #4793d1;
}
.mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #162b50;
}
.mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #162b50;
}
.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #162b50;
}
.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}

.mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-drawer.mat-drawer-push {
  background-color: white;
}
.mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}

[dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
[dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #162b50;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(22, 43, 80, 0.54);
}
.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #162b50;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #4793d1;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(71, 147, 209, 0.54);
}
.mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #4793d1;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #162b50;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(22, 43, 80, 0.54);
}
.mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #162b50;
}
.mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}

.mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}

.mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider.mat-primary .mat-slider-track-fill,
.mat-slider.mat-primary .mat-slider-thumb,
.mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #4793d1;
}
.mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(71, 147, 209, 0.2);
}
.mat-slider.mat-accent .mat-slider-track-fill,
.mat-slider.mat-accent .mat-slider-thumb,
.mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #162b50;
}
.mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(22, 43, 80, 0.2);
}
.mat-slider.mat-warn .mat-slider-track-fill,
.mat-slider.mat-warn .mat-slider-thumb,
.mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #162b50;
}
.mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(22, 43, 80, 0.2);
}

.mat-slider:hover .mat-slider-track-background,
.mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider.mat-slider-disabled .mat-slider-track-background,
.mat-slider.mat-slider-disabled .mat-slider-track-fill,
.mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}

.mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}

.mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover:not([aria-disabled]), .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .mat-step-header:hover {
    background: none;
  }
}
.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #4793d1;
  color: white;
}
.mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.mat-step-header.mat-accent .mat-step-icon-selected,
.mat-step-header.mat-accent .mat-step-icon-state-done,
.mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #162b50;
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon-selected,
.mat-step-header.mat-warn .mat-step-icon-state-done,
.mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #162b50;
  color: white;
}
.mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #162b50;
}
.mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.mat-step-header .mat-step-label.mat-step-label-error {
  color: #162b50;
}

.mat-stepper-horizontal, .mat-stepper-vertical {
  background-color: white;
}

.mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header::before,
.mat-horizontal-stepper-header::after,
.mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header {
  height: 72px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
  padding: 24px 24px;
}

.mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}

.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}

.mat-sort-header-arrow {
  color: #757575;
}

.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.mat-tab-group-inverted-header .mat-tab-nav-bar,
.mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}

.mat-tab-label, .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.mat-tab-label.mat-tab-disabled, .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}

.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-tab-group[class*=mat-background-] > .mat-tab-header,
.mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}

.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(164, 197, 224, 0.3);
}
.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #4793d1;
}
.mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(164, 197, 224, 0.3);
}
.mat-tab-group.mat-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #162b50;
}
.mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(164, 197, 224, 0.3);
}
.mat-tab-group.mat-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #162b50;
}
.mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(164, 197, 224, 0.3);
}
.mat-tab-group.mat-background-primary > .mat-tab-header, .mat-tab-group.mat-background-primary > .mat-tab-link-container, .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #4793d1;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(164, 197, 224, 0.3);
}
.mat-tab-group.mat-background-accent > .mat-tab-header, .mat-tab-group.mat-background-accent > .mat-tab-link-container, .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #162b50;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(164, 197, 224, 0.3);
}
.mat-tab-group.mat-background-warn > .mat-tab-header, .mat-tab-group.mat-background-warn > .mat-tab-link-container, .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #162b50;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}

.mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.mat-toolbar.mat-primary {
  background: #4793d1;
  color: white;
}
.mat-toolbar.mat-accent {
  background: #162b50;
  color: white;
}
.mat-toolbar.mat-warn {
  background: #162b50;
  color: white;
}
.mat-toolbar .mat-form-field-underline,
.mat-toolbar .mat-form-field-ripple,
.mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.mat-toolbar .mat-form-field-label,
.mat-toolbar .mat-focused .mat-form-field-label,
.mat-toolbar .mat-select-value,
.mat-toolbar .mat-select-arrow,
.mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.mat-toolbar .mat-input-element {
  caret-color: currentColor;
}

.mat-toolbar-multiple-rows {
  min-height: 64px;
}

.mat-toolbar-row, .mat-toolbar-single-row {
  height: 64px;
}

@media (max-width: 599px) {
  .mat-toolbar-multiple-rows {
    min-height: 56px;
  }

  .mat-toolbar-row, .mat-toolbar-single-row {
    height: 56px;
  }
}
.mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}

.mat-tree {
  background: white;
}

.mat-tree-node,
.mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}

.mat-tree-node {
  min-height: 48px;
}

.mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-simple-snackbar-action {
  color: #162b50;
}

html, body {
  margin: 0;
  width: 100%;
  height: 100%;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

div {
  box-sizing: border-box;
}

.side-nav-content {
  padding: 15px 10px;
}

a:hover, button:hover {
  cursor: pointer;
}

.multiselect-dropdown .dropdown-btn {
  display: inline-block;
  border: 0px !important;
  border-bottom: 1px solid var(--mat-list-color) !important;
  width: 100%;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.1;
  text-align: left;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
}
.multiselect-dropdown .dropdown-btn .dropdown-down {
  border-top: 5px solid var(--dark-label-color) !important;
  border-left: 5px solid var(--regular-transparent-color) !important;
  border-right: 5px solid var(--regular-transparent-color) !important;
}

.mat-progress-bar-background {
  fill: whitesmoke !important;
}

.mat-button {
  color: var(--link-color);
}

.mat-form-field-type-mat-input.mat-focused .mat-form-field-infix {
  transition: all 500ms;
  padding-top: 5px;
  border: 1px solid var(--primary-color);
}

.middle-fill {
  flex: 1 1 auto;
}

.unit-tooltip {
  white-space: pre-line !important;
}

::ng-deep .unit-tooltip {
  white-space: pre-line !important;
}

.margin-bottom {
  margin-bottom: 15px;
}

.mat-autocomplete-panel {
  min-width: 200px;
}

body {
  background-color: var(--body-bg-color);
}

.name-value {
  display: flex;
  flex-direction: row;
}
.name-value:not(:last-child) {
  margin-bottom: 20px;
}
.name-value .name {
  min-width: 100px;
  padding-right: 15px;
}
.name-value .value {
  font-size: 1.1em;
  display: flex;
  align-items: center;
}
.name-value .value span {
  display: flex;
  align-items: center;
}

.blue-font {
  color: var(--link-color);
}

.grey-font {
  color: var(--regular-grey-color);
}

.capitalized {
  text-transform: capitalize;
}

.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-row .equal-width {
  flex: 1 0 0;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.responsive {
  overflow: auto;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.small-font {
  font-size: 0.8em;
}

.right-aligned {
  margin-left: auto;
}

.link {
  color: var(--link-color);
}

.font-medium-bold {
  font-weight: 500;
}

.no-break {
  white-space: nowrap;
}

.thumb-col {
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
  max-width: 280px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
}

.image-thumb {
  height: 150px;
  width: 150px;
}

.code {
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
}

.badge {
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  color: var(--tabstyle-bg-img-start-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  background-color: var(--light-grey-color);
  border-radius: 10px;
}

.black-pill, .gray-pill, .blue-pill {
  color: var(--regular-white-color);
  padding: 1px 7px;
  border-radius: 11px;
}

.black-pill {
  background-color: var(--regular-black-color);
}

.gray-pill {
  background-color: var(--regular-grey-color);
}

.blue-pill {
  background-color: var(--link-primary-color);
}

.dialog-close {
  margin-top: 10px;
  float: right;
}

.float-right {
  float: right;
}

.large-icon {
  height: 32px;
  width: 32px;
}

.cards-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  flex-direction: column;
}

.cards-container.maximize-width-card {
  display: block;
}

.scrollable-container {
  height: 100%;
  overflow: auto;
}

.blink_me {
  animation: blinker 400ms linear;
}

.search-suggestions-container .match-type {
  font-weight: bold;
  padding: 15px 10px;
}
.search-suggestions-container .mat-menu-item {
  display: flex;
  word-wrap: normal;
  white-space: normal;
  align-items: center;
  align-content: center;
  height: auto;
  min-height: 48px;
  line-height: 24px;
  padding-top: 12px;
  padding-bottom: 12px;
}
.search-suggestions-container .mat-menu-item .suggestion-display {
  flex-grow: 1;
  margin-right: 7px;
}
.search-suggestions-container .mat-menu-item .suggestion-count {
  font-weight: bold;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
::ng-deep .top-mat-menu-backdrop ::ng-deep .cdk-overlay-pane,
::ng-deep .top-mat-menu-backdrop ::ng-deep .cdk-overlay-connected-position-bounding-box {
  z-index: 1002;
}

.textarea-label {
  font-size: 11px;
  padding-bottom: 3.5px;
  line-height: 11px;
  color: var(--dark-label-color);
  font-weight: 400;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

textarea {
  box-sizing: border-box;
  width: 100%;
  border: solid 1.5px var(--textarea-dark-border-color);
  border-radius: 3px;
  padding: 10px;
}
textarea ::placeholder {
  color: var(--dark-label-color);
  font-weight: 400;
  font-size: 14px;
}

.details .subunit {
  display: none;
}
.details .subunit-sequence {
  display: block;
}

.raw .subunit {
  display: block;
}
.raw .subunit-sequence {
  display: none;
}

.loading-overlay-backdrop {
  background: rgba(0, 0, 0, 0.15);
}

.notification-backdrop {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  z-index: 10;
  background-color: var(--notif-backdrop-bg-color);
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-weight: bold;
  color: var(--notif-backdrop-color);
}

.hidden {
  display: none !important;
}

.collapsed {
  width: 0;
  overflow: hidden;
}

.inline-flex {
  display: inline-flex;
}

.disabled {
  pointer-events: none !important;
  cursor: default !important;
  color: var(--structure-import-color) !important;
  text-decoration: none;
}

.mat-select-panel mat-option.mat-option {
  display: flex;
  word-wrap: normal;
  white-space: normal;
  align-items: center;
  align-content: center;
  height: auto;
  min-height: 48px;
  line-height: 24px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.subunit-dialog > .mat-dialog-container {
  height: unset !important;
  max-height: 95%;
}

.mat-drawer-container.mat-sidenav-container {
  position: fixed;
  top: 64px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--regular-transparent-color);
}

mat-sidenav {
  width: 315px;
  padding: 15px 10px;
}

.mat-drawer-content {
  background-color: var(--regular-transparent-color);
}

.expand-sidenav {
  position: fixed;
  left: 0;
  top: 80px;
  z-index: 10000;
  background-color: var(--regular-white-color);
  border: none;
  border-top-right-radius: 25%;
  border-bottom-right-radius: 25%;
  transition: all 800ms;
  padding: 5px 0;
}
.expand-sidenav .mat-icon {
  width: 30px;
  height: 30px;
}

@media (max-width: 1100px) {
  .expand-sidenav {
    display: block;
    opacity: 1;
  }
}
@media (min-width: 1100px) {
  .expand-sidenav {
    display: none;
    opacity: 0;
  }

  mat-sidenav.mat-drawer {
    background-color: var(--regular-transparent-color);
  }
}
table.mat-table {
  width: 100%;
}

td.mat-cell:not(:last-child), td.mat-footer-cell:not(:last-child), th.mat-header-cell:not(:last-child) {
  padding-right: 10px;
}

td.mat-cell, td.mat-footer-cell, th.mat-header-cell {
  padding-top: 10px;
  padding-bottom: 10px;
}

.no-bottom-border tr:last-child td.mat-cell {
  border-bottom: none;
}

.mat-sort-header-content {
  display: block !important;
}

.mat-card {
  max-width: 1228px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 20px;
  margin: auto;
}

.mat-card-title {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  white-space: normal;
}

@media (max-width: 700px) {
  .mat-card-title {
    flex-direction: column;
    align-items: flex-start;
  }
}
.alternate-backgrounds:nth-child(odd) {
  background-color: var(--nth-child-color-2);
}
.alternate-backgrounds:nth-child(odd) ::ng-deep .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
  background-color: var(--nth-child-color-3);
}
.alternate-backgrounds:nth-child(even) ::ng-deep .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
  background-color: var(--nth-child-color-1);
}
.alternate-backgrounds ::ng-deep .mat-expansion-panel, .alternate-backgrounds ::ng-deep .mat-table, .alternate-backgrounds ::ng-deep textarea {
  background-color: var(--regular-transparent-color);
}

.expandable-details {
  overflow: hidden;
  height: auto;
  -webkit-transition: all 750ms ease-out;
  transition: all 750ms ease-out;
}
.expandable-details.details-collapsed {
  max-height: 0;
}
.expandable-details.details-expanded {
  max-height: 1000px;
}

:root {
  --primary-color: #4793d1;
  --secondary-blue: #229fdd;
  --side-nav-bg-color: #002b5c;
  --white-color: #ffffff;
  --regular-red-color: red;
  --regular-black-color: black;
  --regular-white-color: white;
  --regular-offwhite-color: rgb(242, 242, 242);
  --regular-lightgray-color: lightgray;
  --regular-lightgray-color-2: rgb(232, 232, 232);
  --regular-gainsboro-color: gainsboro;
  --regular-grey-color: grey;
  --regular-transparent-color: transparent;
  --regular-blue-color: blue;
  --regular-navy-color: navy;
  --regular-light-yellow-color: #f9f6e8;
  --regular-yellow-color: yellow;
  --regular-orange-color: orange;
  --regular-orangered-color: orangered;
  --regular-green-color: green;
  --regular-darkgreen-color: darkgreen;
  --regular-darkolivegreen-color: darkolivegreen;
  --regular-turquoise-color: turquoise;
  --regular-lightskyblue-color: lightskyblue;
  --regular-darkslateblue-color: darkslateblue;
  --regular-purple-color: purple;
  --regular-magenta-color: magenta;
  --regular-maroon-color: maroon;
  --regular-brown-color: #6e3408;
  --primary-title-color: #0857d6;
  --secondary-title-color: #27288a;
  --lighter-title-color: rgba(0, 0, 0, 0.87);
  --blue-color: #1945bd;
  --blue-bg-color-rgb: rgba(22, 84, 218, 0.753);
  --secondary-blue-color: #1C6EA4;
  --royal-blue-color: #3c19d6;
  --bluish-purple-color: rgb(70, 101, 194);
  --darkgrey-color: #4e4e44;
  --deep-blue-color-rgb: rgb(34, 34, 175);
  --deep-blue-color-rgb-2: rgb(0, 0, 255);
  --secondary-rgb-blue: rgb(71, 147, 209);
  --purple-color: #7a0abbe0;
  --deep-purple-color: #4b4572;
  --brown-color: #rgb(122, 45, 17);
  --maroon-color: rgb(90, 68, 68);
  --orange-color: #E55913;
  --orange-color-2: #d35e04;
  --orange-color-rgb: rgb(207, 157, 63);
  --orange-color-rgb-2: rgb(230, 115, 22);
  --burnt-orange-color: rgb(158, 56, 19);
  --light-orange-color: #f8cf88;
  --green-color: rgb(9, 121, 33);
  --light-green-color: #bbc2a4;
  --yellow-color: rgb(231, 197, 2);
  --mustard-color: rgb(172, 141, 4);
  --light-grey-color: #777;
  --dark-grey-color: #262626;
  --light-yellow-color: rgb(251, 247, 206);
  --light-yellow-color-2: rgba(251, 251, 180, 0.705);
  --light-yellow-color-3: rgba(255, 222, 121, 0.96);
  --red-label-color: rgb(155, 39, 39);
  --dark-grey-bg-color: #60615e;
  --error: rgb(173, 26, 26);
  --link-color: #1565C0;
  --link-primary-color: #448aff;
  --pink-span-color: #c7254e;
  --table-bg-color: #FFFFFF;
  --table-bg-color-2: #f7f7f7;
  --table-th-border-color: #224998;
  --table-th-border-color-2: #D0E4F5;
  --table-th-border-color-3: #d1d1d1;
  --table-th-border-color-4: #5f656b;
  --table-tr-even-bg-color: #f3f3f3;
  --table-tr-even-bg-color-2: #DAE9F5;
  --table-tr-even-bg-color-3: #fbfdf7;
  --table-thead-bg-color: #0B6FA4;
  --table-thead-bg-color-2: #c0ccee;
  --table-thead-bg-color-3: #eeeadc;
  --table-thead-border-color: #444444;
  --table-th-color: #FFFFFF;
  --nth-child-color-1: rgba(128, 128, 128, .15);
  --nth-child-color-2: rgba(68, 138, 255, .07);
  --nth-child-color-3: rgba(68, 138, 255, .15);
  --error-dialog-color: #a94442;
  --error-dialog-bg-color: #f2dede;
  --warning-dialog-color: #8a6d3b;
  --warning-dialog-bg-color: #fcf8e3;
  --notice-dialog-color: black;
  --notice-dialog-bg-color: lightgray;
  --progress-bar-buffer-bg-color: rgba(0, 0, 0, 0.01);
  --progress-spinner-buffer-bg-color: rgb(212, 212, 212);
  --text-color: rgba(0, 0, 0, .5);
  --text-shadow-color: #000;
  --label-color: #404040;
  --dark-label-color: rgba(0, 0, 0, 0.54);
  --spinner-bg-color: rgba(150, 148, 148, 0.15);
  --spinner-stroke-color: #3fb53f;
  --border-color: rgba(0, 0, 0, .1);
  --pale-border-color: #E0E0E0;
  --pale-border-color-rgb: rgb(228, 218, 218);
  --pale-border-color-rgb-2: rgb(247, 241, 241);
  --pale-border-color-rgb-3: rgb(241, 241, 250);
  --pale-border-color-rgb-4: rgb(182, 175, 175);
  --grey-border-color: #CCC;
  --grey-border-color-rgb: rgb(182, 177, 177);
  --deep-purple-border-color-rgb: rgb(67, 70, 148);
  --box-shadow-color: rgba(0, 0, 0, 0.2);
  --box-shadow-color-2: rgba(0, 0, 0, 0.14);
  --box-shadow-color-3: rgba(0, 0, 0, 0.12);
  --box-shadow-color-4: rgba(0, 0, 0, 0.35);
  --box-shadow-color-5: rgba(0, 0, 0, 0.26);
  --box-shadow-color-6: rgba(0, 0, 0, 0.45);
  --box-shadow-grey-color: rgba(145, 140, 145, 1);
  --deprecated-color: rgba(0, 0, 0, .70);
  --lightgray-color: #f1f1f1;
  --lightlightgray-color: #f2f3f4 ;
  --gray-color: #666666;
  --version-text-color: #182c4d;
  --nav-item-a-color: #e6e6e6;
  --chevron-color: rgba(0, 0, 0, 0.6);
  --include-checkbox-border-color: #80CBC4;
  --include-checkbox-bg-color: #26A69A;
  --exclude-checkbox-border-color: #EF9A9A;
  --exclude-checkbox-bg-color: #EF5350;
  --query-bg-color: #ECECEC;
  --query-border-color: #DBDBDB;
  --mat-list-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-label-color: rgb(127, 127, 133);
  --mat-form-field-underline-bg-color: rgb(68, 68, 218);
  --mat-form-field-focused-color: rgb(70, 116, 106);
  --hover-bg-color: rgba(94, 145, 186, 0.2);
  --lock-icon-color: #f0ad4e;
  --notif-success-bg-color: #A7FFEB;
  --notif-error-bg-color: #E57373;
  --success-green-color: #3f914c;
  --success-green-bg-color: #def2de;
  --ref-manager-mat-row-nth-child-color: #f9f9f9;
  --img-linear-gradient-start-color: rgba(0, 0, 0, 1);
  --img-linear-gradient-color: #c2c7cc;
  --textarea-dark-border-color: rgba(0, 0, 0, 0.42);
  --textarea-light-border-color: #b3b3b3;
  --mat-icon-button-bg-color: rgba(242, 242, 242, .85);
  --structure-import-color: #595959;
  --structure-import-default-bg-color: rgba(68, 138, 255, .4);
  --structure-import-success-bg-color: rgba(167, 255, 235, .4);
  --structure-import-error-bg-color: rgba(229, 115, 115, .4);
  --notif-backdrop-bg-color: rgba(255, 255, 255, .8);
  --notif-backdrop-color: #666;
  --hr-color: #333;
  --legend-green-border-color: #16997d;
  --legend-blue-border-color: #174793;
  --legend-blue-border-color-2: #007cba;
  --legend-orange-border-color: #b36d13;
  --legend-red-border-color: #700819;
  --legend-bg-color: #eff8ff;
  --legend-bg-blue-color: #2f6dd7;
  --legend-bg-green-color: #f8fdf8;
  --sub-hierarchy-bg-color: #cee8fb;
  --sub-hierarchy-odd-bg-color: rgba(0, 0, 0, .05);
  --disulfide-color: #cca300;
  --glycosylation-color: #608000;
  --n-glycosylation-color: #0066cc;
  --o-glycosylation-color: #ff6666;
  --fieldset-green-border-color: rgb(33, 136, 20);
  --fieldset-blue-border-color: rgb(27, 15, 184);
  --fieldset-orange-border-color: rgb(179, 101, 12);
  --fieldset-red-border-color: rgb(172, 63, 63);
  --fieldset-brown-border-color: #5f391c;
  --fieldset-box-shadow-color: rgba(110, 104, 110, 0.64);
  --tabstyle-bg-color: #e9f3d9;
  --tabstyle-bg-color-2: #fcf8eb;
  --tabstyle-bg-color-3: #eee2b9;
  --tabstyle-bg-color-4: #cde3fd;
  --tabstyle-bg-color-5: #f3f1f1;
  --tabstyle-bg-color-6: #1565C0;
  --tabstyle-bg-color-7: #3768bd;
  --tabstyle-bg-img-start-color: #fff;
  --tabstyle-bg-img-end-color: #b2b2b2;
  --body-bg-color: rgba(94, 145, 186, 0.12);
}