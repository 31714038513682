.mat-drawer-container.mat-sidenav-container {
    position: fixed;
    top: 64px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--regular-transparent-color);
}

mat-sidenav {
    width: 315px;
    padding: 15px 10px;
}

.mat-drawer-content {
    background-color: var(--regular-transparent-color);
}

.expand-sidenav {
    position: fixed;
    left: 0;
    top: 80px;
    z-index: 10000;
    background-color: var(--regular-white-color);
    border: none;
    border-top-right-radius: 25%;
    border-bottom-right-radius: 25%;
    transition: all 800ms;
    padding: 5px 0;

    .mat-icon {
        width: 30px;
        height: 30px;
    }
}

@media(max-width: 1100px) {
    .expand-sidenav {
        display: block;
        opacity: 1;
    }
}

@media(min-width: 1100px) {
    .expand-sidenav {
        display: none;
        opacity: 0;
    }

    mat-sidenav.mat-drawer {
        background-color: var(--regular-transparent-color);
    }
}
