.middle-fill {
    flex: 1 1 auto;
}
 .unit-tooltip {
  white-space: pre-line !important;
}
::ng-deep .unit-tooltip {
  white-space: pre-line !important;
}
.margin-bottom {
    margin-bottom: 15px;
}

.mat-autocomplete-panel {
  min-width: 200px;
}


body {
 background-color: var(--body-bg-color);
}
.name-value {
    display: flex;
    flex-direction: row;

    &:not(:last-child) {
        margin-bottom: 20px;
    }

    .name {
        min-width: 100px;
        padding-right: 15px;
    }

    .value {
        font-size: 1.1em;
        display: flex;
        align-items: center;

        span {
          display: flex;
          align-items: center;
        }
    }
}

.blue-font {
    color: var(--link-color);
}

.grey-font {
  color: var(--regular-grey-color);
}

.capitalized {
    text-transform: capitalize;
}

.flex-row {
    display: flex;
    flex-direction: row;

    .equal-width {
        flex: 1 0 0;
    }
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.responsive {
    overflow: auto;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.small-font {
    font-size: .8em;
}

.right-aligned {
    margin-left: auto;
}

.link {
    color: var(--link-color);
}

.font-medium-bold {
    font-weight: 500;
}

.no-break {
    white-space: nowrap;
}

.thumb-col{
  text-align:center;
  padding-left:5px;
  padding-right:5px;
  max-width:280px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
}

.image-thumb{
  height:150px;
  width:150px;
}

.code{
  font-family: Menlo,Monaco,Consolas,"Courier New",monospace;
}

.badge {
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  color: var(--tabstyle-bg-img-start-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  background-color: var(--light-grey-color);
  border-radius: 10px;
}

.black-pill, .gray-pill, .blue-pill {
  color: var(--regular-white-color);
  padding: 1px 7px;
  border-radius: 11px;
}

.black-pill {
  background-color: var(--regular-black-color);
}

.gray-pill {
  background-color: var(--regular-grey-color);
}

.blue-pill {
  background-color: var(--link-primary-color);
}

.dialog-close{
  margin-top:10px;
  float:right;
}

.float-right {
  float:right;
}

.large-icon {
  height:32px;
  width:32px;
}

.cards-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  flex-direction: column;
}

.cards-container.maximize-width-card {
  display: block;
}

.scrollable-container {
  height: 100%;
  overflow: auto;
}

.blink_me {
  animation: blinker 400ms linear;
}

.search-suggestions-container {

  .match-type {
    font-weight: bold;
    padding: 15px 10px;
  }

  .mat-menu-item {
    display: flex;
    word-wrap: normal;
    white-space: normal;
    align-items: center;
    align-content: center;
    height: auto;
    min-height: 48px;
    line-height: 24px;
    padding-top: 12px;
    padding-bottom: 12px;

    .suggestion-display {
      flex-grow: 1;
      margin-right: 7px;
    }

    .suggestion-count {
      font-weight: bold;
    }
  }
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

::ng-deep .top-mat-menu-backdrop  {

  ::ng-deep .cdk-overlay-pane,
  ::ng-deep .cdk-overlay-connected-position-bounding-box {
    z-index: 1002;
  }
}

.textarea-label {
  font-size: 11px;
  padding-bottom: 3.5px;
  line-height: 11px;
  color: var(--dark-label-color);
  font-weight: 400;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

textarea {
  box-sizing: border-box;
  width: 100%;
  border: solid 1.5px var(--textarea-dark-border-color);
  border-radius: 3px;
  padding: 10px;

  ::placeholder {
    color: var(--dark-label-color);
    font-weight: 400;
    font-size: 14px;
  }
}

.details {
  & .subunit {
    display: none;
  }

  & .subunit-sequence {
    display: block;
  }
}

.raw {
  & .subunit {
    display: block;
  }

  & .subunit-sequence {
    display: none;
  }
}

.loading-overlay-backdrop {
  background: rgba(0, 0, 0, 0.15);
}

.notification-backdrop {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  z-index: 10;
  background-color: var(--notif-backdrop-bg-color);
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-weight: bold;
  color: var(--notif-backdrop-color);
}

.hidden {
  display: none !important;
}

.collapsed {
  width: 0;
  overflow: hidden;
}

.inline-flex {
  display: inline-flex;
}

.disabled {
  pointer-events: none !important;
  cursor: default !important;
  color: var(--structure-import-color) !important;
  text-decoration: none;
}

.mat-select-panel {

  mat-option.mat-option {
    display: flex;
    word-wrap: normal;
    white-space: normal;
    align-items: center;
    align-content: center;
    height: auto;
    min-height: 48px;
    line-height: 24px;
    padding-top: 12px;
    padding-bottom: 12px;
  }
}

.subunit-dialog > .mat-dialog-container {
  height: unset !important;
  max-height: 95%;
}

