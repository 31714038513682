@import '~@angular/material/theming';

:root {

    --primary-color: #4793d1;
    --secondary-blue: #229fdd;
    --side-nav-bg-color: #002b5c;
    --white-color: #ffffff;
    --regular-red-color: red;
    --regular-black-color: black;
    --regular-white-color: white;
    --regular-offwhite-color: rgb(242, 242, 242);
    --regular-lightgray-color: lightgray;
    --regular-lightgray-color-2: rgb(232, 232, 232);
    --regular-gainsboro-color: gainsboro;
    --regular-grey-color: grey;
    --regular-transparent-color: transparent;
    --regular-blue-color: blue;
    --regular-navy-color: navy;
    --regular-light-yellow-color: #f9f6e8;
    --regular-yellow-color: yellow;
    --regular-orange-color: orange;
    --regular-orangered-color: orangered;
    --regular-green-color: green;
    --regular-darkgreen-color: darkgreen;
    --regular-darkolivegreen-color: darkolivegreen;
    --regular-turquoise-color: turquoise;
    --regular-lightskyblue-color: lightskyblue;
    --regular-darkslateblue-color: darkslateblue;
    --regular-purple-color: purple;
    --regular-magenta-color: magenta;
    --regular-maroon-color: maroon;
    --regular-brown-color: #6e3408;
    --primary-title-color: #0857d6;
    --secondary-title-color: #27288a;
    --lighter-title-color: rgba(0, 0, 0, 0.87);
    --blue-color: #1945bd;
    --blue-bg-color-rgb: rgba(22, 84, 218, 0.753);
    --secondary-blue-color: #1C6EA4;
    --royal-blue-color: #3c19d6;
    --bluish-purple-color: rgb(70, 101, 194);
    --darkgrey-color: #4e4e44;
    --deep-blue-color-rgb: rgb(34, 34, 175);
    --deep-blue-color-rgb-2: rgb(0, 0, 255);
    --secondary-rgb-blue: rgb(71, 147, 209);
    --purple-color: #7a0abbe0;
    --deep-purple-color: #4b4572;
    --brown-color: #rgb(122, 45, 17);
    --maroon-color: rgb(90, 68, 68);
    --orange-color: #E55913;
    --orange-color-2: #d35e04;
    --orange-color-rgb: rgb(207, 157, 63);
    --orange-color-rgb-2: rgb(230, 115, 22);
    --burnt-orange-color: rgb(158, 56, 19);
    --light-orange-color: #f8cf88;
    --green-color: rgb(9, 121, 33);
    --light-green-color: #bbc2a4;
    --yellow-color: rgb(231, 197, 2);
    --mustard-color: rgb(172, 141, 4);
    --light-grey-color: #777;
    --dark-grey-color: #262626;
    --light-yellow-color: rgb(251, 247, 206);
    --light-yellow-color-2: rgba(251, 251, 180, 0.705);
    --light-yellow-color-3: rgba(255, 222, 121, 0.96);
    --red-label-color: rgb(155, 39, 39);
    --dark-grey-bg-color: #60615e;
    --error: rgb(173, 26, 26);
    --link-color: #1565C0;
    --link-primary-color: #448aff;
    --pink-span-color: #c7254e;
    --table-bg-color: #FFFFFF;
    --table-bg-color-2: #f7f7f7;
    --table-th-border-color: #224998;
    --table-th-border-color-2: #D0E4F5;
    --table-th-border-color-3: #d1d1d1;
    --table-th-border-color-4: #5f656b;
    --table-tr-even-bg-color: #f3f3f3;
    --table-tr-even-bg-color-2: #DAE9F5;
    --table-tr-even-bg-color-3: #fbfdf7;
    --table-thead-bg-color: #0B6FA4;
    --table-thead-bg-color-2: #c0ccee;
    --table-thead-bg-color-3: #eeeadc;
    --table-thead-border-color: #444444;
    --table-th-color: #FFFFFF;
    --nth-child-color-1: rgba(128, 128, 128, .15);
    --nth-child-color-2: rgba(68, 138, 255, .07);
    --nth-child-color-3: rgba(68, 138, 255, .15);
    --error-dialog-color: #a94442;
    --error-dialog-bg-color: #f2dede;
    --warning-dialog-color: #8a6d3b;
    --warning-dialog-bg-color: #fcf8e3;
    --notice-dialog-color: black;
    --notice-dialog-bg-color: lightgray;
    --progress-bar-buffer-bg-color: rgba(0, 0, 0, 0.01);
    --progress-spinner-buffer-bg-color: rgb(212, 212, 212);
    --text-color: rgba(0, 0, 0, .5);
    --text-shadow-color: #000;
    --label-color: #404040;
    --dark-label-color: rgba(0, 0, 0, 0.54);
    --spinner-bg-color: rgba(150, 148, 148, 0.15);
    --spinner-stroke-color: #3fb53f;
    --border-color: rgba(0, 0, 0, .1);
    --pale-border-color: #E0E0E0;
    --pale-border-color-rgb: rgb(228, 218, 218);
    --pale-border-color-rgb-2: rgb(247, 241, 241);
    --pale-border-color-rgb-3: rgb(241, 241, 250);
    --pale-border-color-rgb-4: rgb(182, 175, 175);
    --grey-border-color: #CCC;
    --grey-border-color-rgb: rgb(182, 177, 177);
    --deep-purple-border-color-rgb: rgb(67, 70, 148);
    --box-shadow-color: rgba(0, 0, 0, 0.2);
    --box-shadow-color-2: rgba(0, 0, 0, 0.14);
    --box-shadow-color-3: rgba(0, 0, 0, 0.12);
    --box-shadow-color-4: rgba(0, 0, 0, 0.35);
    --box-shadow-color-5: rgba(0, 0, 0, 0.26);
    --box-shadow-color-6: rgba(0, 0, 0, 0.45);
    --box-shadow-grey-color: rgba(145, 140, 145, 1);
    --deprecated-color: rgba(0, 0, 0, .70);
    --lightgray-color: #f1f1f1;
    --lightlightgray-color: #f2f3f4 ;
    --gray-color: #666666;
    --version-text-color: #182c4d;
    --nav-item-a-color: #e6e6e6;
    --chevron-color: rgba(0, 0, 0, 0.6);
    --include-checkbox-border-color: #80CBC4;
    --include-checkbox-bg-color: #26A69A;
    --exclude-checkbox-border-color: #EF9A9A;
    --exclude-checkbox-bg-color: #EF5350;
    --query-bg-color: #ECECEC;
    --query-border-color: #DBDBDB;
    --mat-list-color: rgba(0, 0, 0, 0.87);
    --mat-form-field-label-color: rgb(127, 127, 133);
    --mat-form-field-underline-bg-color: rgb(68, 68, 218);
    --mat-form-field-focused-color: rgb(70, 116, 106);
    --hover-bg-color: rgba(94, 145, 186, 0.2);
    --lock-icon-color: #f0ad4e;
    --notif-success-bg-color: #A7FFEB;
    --notif-error-bg-color: #E57373;
    --success-green-color: #3f914c;
    --success-green-bg-color: #def2de;
    --ref-manager-mat-row-nth-child-color: #f9f9f9;
    --img-linear-gradient-start-color: rgba(0, 0, 0, 1);
    --img-linear-gradient-color: #c2c7cc;
    --textarea-dark-border-color: rgba(0, 0, 0, 0.42);
    --textarea-light-border-color: #b3b3b3;
    --mat-icon-button-bg-color: rgba(242, 242, 242, .85);
    --structure-import-color: #595959;
    --structure-import-default-bg-color: rgba(68, 138, 255, .4);
    --structure-import-success-bg-color: rgba(167, 255, 235, .4);
    --structure-import-error-bg-color: rgba(229, 115, 115, .4);
    --notif-backdrop-bg-color: rgba(255, 255, 255, .8);
    --notif-backdrop-color: #666;
    --hr-color: #333;
    --legend-green-border-color: #16997d;
    --legend-blue-border-color: #174793;
    --legend-blue-border-color-2: #007cba;
    --legend-orange-border-color: #b36d13;
    --legend-red-border-color: #700819;
    --legend-bg-color: #eff8ff;
    --legend-bg-blue-color: #2f6dd7;
    --legend-bg-green-color: #f8fdf8;
    --sub-hierarchy-bg-color: #cee8fb;
    --sub-hierarchy-odd-bg-color: rgba(0, 0, 0, .05);
    --disulfide-color: #cca300;
    --glycosylation-color: #608000;
    --n-glycosylation-color: #0066cc;
    --o-glycosylation-color: #ff6666;
    --fieldset-green-border-color: rgb(33, 136, 20);
    --fieldset-blue-border-color: rgb(27, 15, 184);
    --fieldset-orange-border-color: rgb(179, 101, 12);
    --fieldset-red-border-color: rgb(172, 63, 63);
    --fieldset-brown-border-color: #5f391c;
    --fieldset-box-shadow-color: rgba(110, 104, 110, 0.64);
    --tabstyle-bg-color: #e9f3d9;
    --tabstyle-bg-color-2: #fcf8eb;
    --tabstyle-bg-color-3: #eee2b9;
    --tabstyle-bg-color-4: #cde3fd;
    --tabstyle-bg-color-5: #f3f1f1;
    --tabstyle-bg-color-6: #1565C0;
    --tabstyle-bg-color-7: #3768bd;
    --tabstyle-bg-img-start-color: #fff;
    --tabstyle-bg-img-end-color: #b2b2b2;
    --body-bg-color: rgba(94, 145, 186, 0.12);
}
