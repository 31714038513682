table.mat-table {
    width: 100%;
}

td.mat-cell:not(:last-child), td.mat-footer-cell:not(:last-child), th.mat-header-cell:not(:last-child) {
    padding-right: 10px;
}

td.mat-cell, td.mat-footer-cell, th.mat-header-cell {
    padding-top: 10px;
    padding-bottom: 10px;
}

.no-bottom-border {
    tr:last-child {
        td.mat-cell {
            border-bottom: none;
        }
    }
    
}

.mat-sort-header-content {
    display: block !important;
}
