.mat-card {
    max-width: 1228px;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 20px;
    margin: auto;
}

.mat-card-title {
    display: flex;
    box-sizing: border-box;
    width: 100%;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;
    white-space: normal;
}

@media(max-width: 700px) {
    .mat-card-title {
        flex-direction: column;
        align-items: flex-start;
    }
}

.alternate-backgrounds {

  &:nth-child(odd) {
    background-color: var(--nth-child-color-2);

    ::ng-deep {

      .mat-expansion-panel:not(.mat-expanded):not([aria-disabled="true"]) .mat-expansion-panel-header:hover {
        background-color: var(--nth-child-color-3);
      }
    }
  }

  &:nth-child(even) {
    ::ng-deep {

      .mat-expansion-panel:not(.mat-expanded):not([aria-disabled="true"]) .mat-expansion-panel-header:hover {
        background-color: var(--nth-child-color-1);
      }
    }
  }

  ::ng-deep {
    .mat-expansion-panel, .mat-table, textarea {
      background-color: var(--regular-transparent-color);
    }
  }
}
